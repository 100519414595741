<template>
  <div class="pedidosVue py-5">
    <div class="welcomeMobile" v-if="this.sizeWindow <= 767">
        <router-link to="/" class="imgWelCont">
          <img src="../assets/image/iconesMobile/grayArrow.svg" class="grayArrow">
        </router-link>
        <div class="txtWelCont">
          <p>Pedidos</p>
        </div>
    </div>
    <div class="container">
      <div class="col-md-12">
        <div class="col-md-12" :style="'height:100%;'">
          <div class="col-md-2 px-0">
            <div class="d-flex my-3">
              <p
                class="tab mb-0"
                :class="cp == 'pedidoCp' ? 'active' : ''"
                @click.prevent="trocaCP('pedidoCp')"
              >
                Pedidos
              </p>
              <p class="titulo_dash mb-0 mx-1">/</p>
              <p
                class="tab mb-0"
                :class="cp == 'totalDIa' ? 'active' : ''"
                @click.prevent="trocaCP('totalDIa')"
              >
                Vendas
              </p>
            </div>
          </div>
        </div>
        <transition name="horizontal">
          <component :is="cp" />
        </transition>
        <!-- <loader color="#e0a638" size="100"  /> -->
      </div>
    </div>
  </div>
</template>

<script>
import "../../node_modules/@fortawesome/fontawesome-free/css/all.css";
import "../../node_modules/vue-ads-pagination/dist/vue-ads-pagination.css";

import VueAdsPagination, { VueAdsPageButton } from "vue-ads-pagination";
import pedidoCp from "@/components/pedidos/pedidos";
import totalDIa from "@/components/pedidos/day";
import loader from "@/components/utils/_Load";
import { HalfCircleSpinner } from "epic-spinners";
export default {
  components: {
    loader,
    pedidoCp,
    totalDIa,
    VueAdsPagination,
    VueAdsPageButton,
    HalfCircleSpinner
  },

  data() {
    return {
      cp: "pedidoCp",
      sizeWindow: 0,
    };
  },
  methods: {
    trocaCP(val) {
      this.cp = val;
      if (val === "totalDIa") {
        this.loadDay = true;
      }
    }
  },
  mounted() {
    this.sizeWindow = $(window).width();
  },
};
</script>

<style scoped>
.active {
  color: #fff !important;
}

.tab {
  color: #175d93;
  cursor: pointer;
  font-weight: 700;
}
.dropdownCp {
  display: none;
}
.service_name {
  opacity: 1;
}
.fas {
  font-size: 20px;
  transition: all 0.2s linear;
  color: #175d93;
  cursor: pointer;
}
.fas:hover {
  color: #fff;
}
.nav {
  font-size: 12px;
}
.pedidosVue {
  background: #2474b2;
}
.box {
  min-height: 60px;
  border: 1px solid #e0a638;
  background: #357eb8;
  box-shadow: 0px 3px 6px 0 rgba(68, 68, 68, 0.3);
}
.numeroPedido {
  font-size: 14px;
  font-weight: 700;
  color: #e0a638;
}
.servico_descricao {
  color: #fff;
  font-weight: 400;
  font-size: 14px;
}
.bolder {
  font-weight: 700;
  font-size: 14px;
}
@media screen and (max-width:767px){
  .pedidosVue{
    padding-top: 0!important;
  }
  .welcomeMobile{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background: #217ABF;
    height: 40px;
    margin-bottom: 3%;
  }
  .imgWelCont{
    width: 57%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .txtWelCont{
    width: 77%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .txtWelCont p{
    margin-bottom: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
  }
  .imgWelCont img{
    padding-left: 7%;
    width: 24%;
  }
}
</style>
