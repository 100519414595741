<template>
    <div>
        <div v-bind:class="centro[ident].status">
            <p>{{centro[ident].status}}</p>
        </div>
    </div>
</template>

<script>
export default {
    props:[
        "ident"
    ],
    data() {
        return{
            centro:[
                {
                    status:"Cancelado"
                },
                {
                    status:"Aberto"
                },
                {
                    status:"Finalizado"
                }
            ]
        }
    },
    methods: {

    },
    
}
</script>

<style scoped>
   
    .Finalizado{
        background-color: rgb(98, 203, 95);
        color:#FFFF;
        border-radius: 50px;
        width: 80px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        
    }
    .Finalizado p{
        margin: 0px;
    }
    .Aberto{
        background-color: #f65757;
        color:#FFFF;
        border-radius: 50px;
        width: 60px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .Aberto p{
        margin: 0px;
    }
    .Cancelado{
        background-color: orange;
        color:#FFFF;
        border-radius: 50px;
        width: 80px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .Cancelado p {
        margin: 0px;
    }
</style>