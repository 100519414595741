<template>
  <div class="row">
    <div class="col-md-12" id="idRowContainer" style="max-height: 200px; ">
      <div
        class="row boxColor align-items-center"
        v-for="(item, i) in pedidos.servicesOrder"
        :key="i"
      >
        <div class="col-md-6">
          <p class="servico_descricao mb-0">{{ item.serviceName }}</p>
        </div>
        <div class="col-md-4">
          <p class="servico_descricao mb-0 bolder">
            {{ item.serviceValue | currency }}
          </p>
          {{ item.prints.serviceName }}
        </div>
        <div class="col-md-2">
          <div class="row">
            <div class="col-md-4">
              <i
                v-if="item.prints.length > 0 && item.prints[0].file"
                class="fas fa-paperclip"
                @click.prevent="servicesOrders(index, i)"
              >
              </i>
            </div>
            <div
              class="col-md-4"
              id="tooltip"
            >

              <i v-if="item.status && (item.messageCancellation || getMessageCancellationPedido())"
                class="fas fa-info-circle"
                data-toggle="tooltip"
                data-placement="right"
                :title="(item.messageCancellation != null ? item.messageCancellation : messageServiceCancelatio)"
              >
              </i>
            </div>
            <div class="col-md-4">
                  <font-awesome-icon v-if="($store.getters.getUser.profile == 1 || $store.getters.getUser.profile == 2) && i%2 != 0 && item.status != 1 && pedidos.servicesOrder.length > 1" 
                    class="delete primaryColorDelete" 
                    icon="fa-trash-can"
                    @click="showModalDelete(index, i, item.orderServiceId, item.serviceName)"/>
                  <font-awesome-icon v-else-if="($store.getters.getUser.profile == 1 || $store.getters.getUser.profile == 2) && item.status != 1 && pedidos.servicesOrder.length > 1" 
                    class="delete secundaryColorDelete" 
                    icon="fa-trash-can"
                    @click="showModalDelete(index, i, item.orderServiceId, item.serviceName)"/>
            </div>
          </div>
        </div>
        <modalServiceDelete :id="`modalServiceDelete${index}_${i}`" @reload-orders="reloadOrders" :orderServiceId="orderServiceId" :serviceName="serviceName"/>
        <modalItem
          :id="`modalList${index}_${i}`"
          :servico="item.prints.length > 0 ? item.prints[0].file : ''"
        />
        <!-- <div class="col-md-4" v-if="pedidos.messageCancellation">
          <p class="servico_cancelado" id="canceled">
           <strong>Motivo cancelamento:</strong> {{ pedidos.messageCancellation }}.
          </p>
        </div> -->
        <!-- <modalImage
                v-if="item.prints.length > 0"
          :i="i"
          :id="`modalPdf${index}_${i}`"
          :imagesPdf.sync="imagesPdf"
          ref="modalInit"
          :classOwl="classOwl"
        /> -->
        <!-- <modalImage
          :i="i"
          :id="`modalPdf${index}_${i}`"
          :imagesPdf.sync="imagesPdf"
          ref="modalInit"
          :classOwl="classOwl"
        /> -->
      </div>
      <div
        class="row services boxColor"
        style="max-height: 200px; "
        v-if="pedidos.status === 3"
      >
        <p class="servico_descricao pays mb-3 col-md-3">Pagamento</p>
        <div
          class="row cardPay  col-md-3"
          v-for="(items, name, index) in pedidos.paymentValues"
          :key="index"
        >
          <p class="servico_descricao ">
            {{ `${name}: ${valueConvert(items)} ` }}
          </p>
        </div>
      </div>
      <div
        class="row services boxColor"
        style="max-height: 200px;;"
        v-if="pedidos.status === 1"
      >
        <p class="servico_descricao pays mb-3 col-md-3">Motivo Cancelamento:</p>
        <div>
          <p class="servico_cancelado">
            {{ pedidos.messageCancellation }}
          </p>
        </div>
      </div>
      <div></div>
      <div
        class="row boxColor align-items-center last py-3"
        style="max-height: 200px; height:200px;"
        v-if="pedidos.status == 2 && $store.getters.getUser.profile == 3"
      >
        <div
          class="col-md-2 ml-auto d-flex h-100 align-items-center mr-4"
          style="max-height: 200px; "
        >
          <p class="servico_descricao mb-0" v-if="pedidos.prints > 0">
            <!-- Valor/página: R$ 0,20 Total Páginas: {{ prints }}
            Pagamento: Espécie -->
          </p>
        </div>
        <div
          class="
            col-md-1
            text-center
            bg_tital
            d-flex
            h-100
            align-items-center
            mx-4
            justify-content-center
          "
        >
          <p class="servico_descricao mb-0" style="max-height: 200px;">
            total
            <br />
            <strong>{{ pedidos.total | currency }}</strong>
          </p>
        </div>
        <div class="col-md-2 d-flex h-100 align-items-center ml-4">
          <button class="btn_default bt1" @click="search()">
            <img src="@/assets/image/icones/PlusIcon.svg" />
            Add Serviços
          </button>
        </div>
        <div class="col-md-2 d-flex h-100 align-items-center mr-4">
          <button
            class="btn_default bt2"
            @click.prevent="setPedido('/finalizar-servico')"
          >
            <img
              src="@/assets/image/icones/CheckboxIcon.png"
              width="13px"
              height="13px"
            />
            Finalizar Pedido
          </button>
        </div>
      </div>
    </div>
    <!-- <modalDegustacao/> -->
  </div>
</template>

<script>
//  @click.prevent="showModal(index, i, item.prints[0])"

import modalImage from "./modalImage";
import modalItem from "./modalList";
import modalServiceDelete from "./modalServiceDelete";
//import modalDegustacao from "@/components/modal/modalDegustacao";
export default {
  components: { 
    modalImage, 
    modalItem, 
    modalServiceDelete
    //modalDegustacao 
  },
  props: ["pedidos", "total", "prints", "status", "index", "itemId"],
  data() {
    return {
      imagesPdf: [],
      classOwl: "",
      id: "",
      orderServiceId: "",
      serviceName: "",
      messageServiceCancelatio: "O serviço foi cancelado ao cancelar o pedido"
    };
  },
  mounted() {
    this.enableTooltip();
  },
  methods: {
    search() {
      $(".input_search_list input").click();
    },
    enableTooltip() {
      $(function() {
        $('[data-toggle="tooltip"]').tooltip();
      });
    },
    setPedido(rota) {
      const getOrder = {
        id: this.pedidos.id,
        servicesOrder: this.pedidos.servicesOrder
          .filter(pf => pf.status == 0)
          .map(pf => ({
            orderServiceId: pf.orderServiceId,
            orderId: pf.orderId,
            descriptionValue: pf.descriptionValue,
            hasPrint: pf.hasPrint,
            quantityItens: pf.quantity,
            subTotal: pf.serviceValue,
            serviceName: pf.serviceName,
            dataDispatcher: pf.dataDispatcher
          })),
        total: this.pedidos.total,
        clientRequired: this.pedidos.clientRequired
      };

      this.$store.commit("set_pedido", getOrder);
      this.$store.commit("changeOrderId", getOrder.id);
      this.$nextTick(() => {
        if (rota != 0) {
          this.$router.push(rota);
        }
      });
    },
    getMessageCancellationPedido() {
      return this.pedidos.messageCancellation;
    },
    servicesOrders(index, i) {
      $(`#modalList${index}_${i}`).modal("show");
    },
    showModalDelete(index, i, orderServiceId, serviceName) {
      this.orderServiceId = orderServiceId;
      this.serviceName = serviceName;
      if (this.$store.getters.get_tenant.type == 4 && this.$store.getters.getUser.profile != 1) {
        this.$emit("showModalDegustacaoServico");
      } else {
        $(`#modalServiceDelete${index}_${i}`).modal("show");
      }
    },
    reloadOrders() {
      this.$emit('get-orders', this.$props.index, this.$props.itemId);
    },
    showModal(index, i, linkForView) {
      $(`#modalPdf${index}_${i}`).modal("show");
      this.imagesPdf = "";
      var array = [];
      var arrayParse = JSON.parse(linkForView);

      arrayParse.forEach((element, i) => {
        fetch(`${element}`, {
          method: "GET",

          headers: {
            Authorization: `Bearer ${this.$store.getters.getterToken}`
          }
        })
          .then(res => {
            return res.json();
          })
          .then(response => {
            this.imagesPdf = "";
            array.push(`data:image/jpeg;base64,${response.result}`);
            if (array.length == arrayParse.length) {
              if (response.result != "") {
                this.imagesPdf = array;
                if (this.imagesPdf.length > 1) {
                  this.classOwl = "owl-carousel";
                  this.$nextTick(() => {
                    this.$refs.modalInit[i].owlInit();
                  });
                } else {
                  this.classOwl = "";
                }
              } else {
                this.$notify({
                  group: "erros",
                  type: "error",
                  text: `<i class="icon ion-close-circled"></i>Ops algo deu errado`
                });
                this.$nextTick(() => {
                  $(`#modalPdf${index}_${i}`).modal("hide");
                });
              }
            }
          });
      });
    }
  }
};
</script>

<style scoped>
.last {
  height: 80px !important;
}

.pays {
  width: 80%;
  display: "block";
}
.servico_cancelado {
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  padding-right: 35px;
}
.servico_descricao {
  color: #fff;
  font-weight: 400;
  font-size: 14px;
}
.bolder {
  font-weight: 700;
  font-size: 14px;
}
.fas {
  font-size: 20px;
  transition: all 0.2s linear;
  color: #fff;
  cursor: pointer;
}
.fas:hover {
  color: #fff;
}
.boxColor {
  height: 50px;
}
.btn_default {
  height: 40px;
}
.bt1 {
  background-color: #f38235 !important;
}
.bt1:hover {
  background-color: #e0a638 !important;
  border: none;
}

.bt2 {
  background-color: rgb(98, 203, 95) !important;
}
.bt2:hover {
  background-color: rgb(16, 162, 16) !important;
  border: none;
}

.boxColor:nth-child(odd) {
  background-color: #175d93;
}
.boxColor:nth-child(even) {
  background-color: #357eb8;
}
.boxColor:nth-child(odd) .bg_tital {
  background-color: #357eb8;
}
.boxColor:nth-child(even) .bg_tital {
  background-color: #175d93;
}

#idRowContainer {
  overflow-y: auto;
  overflow-x: hidden;
}

.cardPay {
  display: flex;
  justify-content: space-between;
  height: 50px;
  align-items: center;
}
.services {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0;
}

.services p {
  margin: 0 !important;
}

.one {
  justify-content: flex-start;
}

.one p {
  margin-left: 20px;
}
#tooltip {
  display: flex;
  float: right !important;
  width: 20px !important;
}
.delete {
  font-size: 20px;
  transition: all 0.2s linear;
  cursor: pointer; 
}
.primaryColorDelete {
  color: #175d93;
}
.secundaryColorDelete {
  color: #5B7D97;
}
.delete:hover {
  color: #f65757;
}
.mt-4,
.my-4 {
  margin-top: 1rem !important;
}
@media screen and (max-width:767px){
  .cardPay{
    margin-left: 0px;
  }
  .servico_cancelado{
    padding-left: 15px!important;
  }
  
}
</style>
