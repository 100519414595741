<template>
  <div
    class="modal fade"
    :id="id"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body containers">
          <h1>Arquivo impresso</h1>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <!-- <div
            class="d-flex align-items-center justify-content-center"
            style="height: 250px"
            v-if="imagesPdf == ''"
          >
            <loader color="#e0a638" size="100" />
          </div> -->
          <ul>
            <li class="boxColor">{{servico}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loader from "@/components/utils/_Load";
export default {
  components: { loader },
  props: ['id','servico'],
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.modal-content {
  border: 1px solid #357eb8;
  box-shadow: -2px 6px 8px 2px rgba(68, 68, 68, 0.3);
}
.close {
  font-size: 40px;
  font-weight: 700;
  line-height: 1;
  color: #e0a638;
  transition: all 0.2s linear;
  opacity: 0.5;
  position: absolute;
  right: 0px;
  text-shadow: none !important;
  z-index: 6;
  top: 0px;
  padding: 1rem;
}
.close:hover {
  color: #dc3545;
}
.containers {
  min-height: 300px;
  background: #2474b2;
  color: #fff;
}

ul {
  border: 1px solid #e0a638;
  padding: 0;
  margin: 0%;
  list-style: none;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
  margin: 42px 0;
}

li {
  padding: 2px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  min-height: 40px;
  word-break: break-all
}

.boxColor:nth-child(odd) {
  background-color: #175d93;
}

h1 {
  font-size: 19px;
  margin: 9px 0;
}
</style>
