<template>
  <div class="row">

    <inputFilterVue
        :positionIcon="'justify-content-end'"
        :positionContainer="''"
        :show="showModal"
        @openFilter="showModalFilter"
        @filter="filter"
        @filterClear="filterClear"
      >

        <label class="labels" id="labelOrange">Período</label>
        <label class="labels" for="dateInit">Data Inicial</label>
        <input class="input" 
        type="date" 
        id="dateInit" 
        name=""
        :value="dataInit"
        @input="$emit('update:dataInit', $event.target.value)"/>

        <label class="labels" for="dateFinish">Data Final</label>
        <input class="input" 
        type="date" 
        id="dateFinish" 
        :value="dataFinish"
        @input="$emit('update:dataFinish', $event.target.value)" />
        
        <label class="labels">Status</label>
        <select class="input"
          v-model="statusEmit"
          >
          <option value=""></option>
          <option value="0">Todos</option>
          <option value="2">Aberto</option>
          <option value="3">Finalizado</option>
          <option value="1">Cancelado</option>
        </select>
        <label class="labels">Tipo de Moeda</label>
        <Multiselect class="multi" 
          v-model="typePayEmit"
          :options="typePay"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :show-labels="false"
          :searchable="true"
          :maxHeight="100"
          placeholder="Selecione o Tipo"
          >
          <!-- <option value=""></option>
          <option value="0">Dinheiro</option>
          <option value="1">Cartão</option>
          <option value="2">Transferencia/Pix</option> -->
        </Multiselect>
        <label class="labels">ID do Pedido</label>
        <input 
        class="input" 
        placeholder="Insira o ID"
        v-model="idPedido"/>
      </inputFilterVue>
  </div>
</template>

<script>
import inputFilterVue from "../filters/dropdownFilter.vue";
import Multiselect from "vue-multiselect";
export default {
  props: ["status", "dataInit", "dataFinish"],
  components:{
    inputFilterVue,
    Multiselect
  },
  data(){
    return{
      showModal: false,
      statusEmit: "",
      typePayEmit: "",
      idPedido: "",
      typePay:['Dinheiro', 'Cartão', 'Transferencia/Pix']
    }
  },
  methods:{
    filter(){
      this.filterStatus()
      this.filterPayEmit()
      this.filterId()
      this.$emit('getOrders', 0)
    },
    filterClear(){
      this.idPedido = ""
      this.statusEmit = ""
      this.typePayEmit = ""
      this.$emit('clear')
    },
    filterId(){
      this.$emit('clFilter', this.idPedido)
    },
    filterStatus(){
      this.$emit('clStatus', this.statusEmit)
    },
    filterPayEmit(){
      this.$emit('clPay', this.typePayEmit)
    },
    showModalFilter(param) {
      if (param && this.showModal) {
        this.showModal = false;
      } else if (!param && this.showModal) {
        this.showModal = param;
      } else {
        this.showModal = param;
      }
    },
  },
  
};
</script>

<style scoped>
.multi{
  margin-bottom: 10px;
}
.row{
  display: flex!important;
  justify-content: flex-end!important;
}
#labelOrange{
  color: orange!important;
}

</style>
