<template>
  <div class="col-md-12">
    <div class="row">
      <half-circle-spinner
        v-if="load"
        class="mx-auto my-5"
        :animation-duration="1000"
        :size="100"
        color="#fff"
      />
      <div
        v-else
        class="col-md-3"
        style="margin-bottom: 15px;margin-top: 15px;"
        v-for="(item, index) in day"
        :key="index"
      >
        <div class="box mx-0">
          <div class="row">
            <div class="col-md-12">
              <div
                class="top_box text-center d-flex align-items-center justify-content-center"
                :class="item.data == 'Hoje' ? 'day_bg' : 'affter_bg'"
              >
                <h4 class="indicador_h4 mb-0">{{ item.data }}</h4>
              </div>
              <div
                class="body_box text-center d-flex align-items-center justify-content-center"
              >
                <h4 class="indicador_h4 mb-0 positive">
                  {{ item.total | currency }}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HTTP from "@/api_system";
import { HalfCircleSpinner } from "epic-spinners";
export default {
  components: {
    HalfCircleSpinner
  },
  data() {
    return {
      day: [],
      load: true
    };
  },
  mounted() {
    this.getValuesDay();
  },
  methods: {
    getValuesDay() {
      this.day = [];
      const getValuesDayOrders = `services/app/Order/GetListOrderDay`;

      HTTP.get(getValuesDayOrders, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.getterToken}`
        }
      })
        .then(response => {
          this.load = false;
          this.day = response.data.result;
        })
        .catch(error => {
          this.load = false;
          this.$notify({
            group: "erros",
            type: "error",
            text: `<i class="icon ion-close-circled"></i>Ops algo deu errado`
          });
        });
    }
  }
};
</script>

<style scoped>
.box {
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  border: 2px solid #ffffff;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px 0 rgba(68, 68, 68, 0.3);
  height: 100%;
}
.top_box {
  height: 60px;

  box-shadow: 0px 3px 6px 0 rgba(68, 68, 68, 0.3);
}

.vue-ads-justify-end {
  display: none !important;
}
.indicador_h4 {
  font-weight: 500;
  color: #ffffff;
  font-size: 14px;
}
.body_box {
  height: 80px;
}
.affter_bg {
  background-color: #175d93;
}
.day_bg {
  background-color: #35b890 !important;
}
.positive {
  font-size: 25px;
  font-weight: 700;
  color: #175d93;
}

.valor_h4 {
  font-weight: 700;
  font-size: 25px;
}
</style>
