<template>
	<div
		class="modal fade"
		:id="id"
		tabindex="-1"
		role="dialog"
		aria-labelledby="exampleModalLabel"
		aria-hidden="true"
	>
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-body">
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
					<div
						class="d-flex align-items-center justify-content-center"
						style="height:250px;"
						v-if="imagesPdf == ''"
					>
						<loader color="#e0a638" size="100" />
					</div>
					<div :class="classOwl" :id="`owlInit${i}`">
						<div v-for="(item,index) in imagesPdf" :key="index" class="col-md-12 px-0">
							<img :src="item" alt style="width:100%;" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import loader from "@/components/utils/_Load";
	export default {
		components: { loader },
		props: ["i", "imagesPdf", "id", "classOwl"],
		data() {
			return {};
		},
		mounted() {},
		methods: {
			owlInit() {
				$(`#owlInit${this.i}`).owlCarousel({
					loop: false,
					nav: true,
					dots: false,
					navText: [
						'<i class="fa fa-angle-left position-absolute icon_owl_left icon_owl" aria-hidden="true" style=" bottom: 50% !important;"></i>',
						'<i class="fa fa-angle-right position-absolute  icon_owl_right icon_owl" aria-hidden="true" style=" bottom: 50% !important;" ></i>'
					],

					items: 1
				});
			}
		}
	};
</script>

<style scoped>
.modal-content {
	border: 1px solid #357eb8;
	box-shadow: -2px 6px 8px 2px rgba(68, 68, 68, 0.3);
}
.close {
	font-size: 40px;
	font-weight: 700;
	line-height: 1;
	color: #e67979;
	transition: all 0.2s linear;
	opacity: 0.5;
	position: absolute;
	right: 0px;
	text-shadow: none !important;
	z-index: 6;
	top: 0px;
	padding: 1rem;
}
.close:hover {
	color: #dc3545;
}
.owl-carousel >>> .icon_owl {
	color: #f38235;
}
.owl-carousel >>> .icon_owl:hover {
	color: #e0a638;
}
</style>
